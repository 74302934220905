import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { LocaleProvider } from '../context/localeContext';
import Layout from '../components/Layout';
import Section from '../components/Section';
import Event from '../templates/Event';
import NewsletterSignupForm from '../components/NewsletterSignupForm';

const EventTemplate = ({ data, pageContext }) => {
  return (
    <LocaleProvider locale={pageContext.locale}>
      <Layout>
        <Event
          title={data.event.frontmatter.title}
          date={data.event.frontmatter.date}
          duration={data.event.frontmatter.duration}
          location={data.event.frontmatter.location}
          registrationLink={data.event.frontmatter.registrationLink}
          heroImage={data.event.frontmatter.image}
          content={data.event.htmlAst}
          tags={data.event.frontmatter.tags}
        />
        <Section>
          <NewsletterSignupForm />
        </Section>
      </Layout>
    </LocaleProvider>
  );
};

EventTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default EventTemplate;

export const pageQuery = graphql`
  query EventByID($id: String!, $relatedPages: [String]) {
    event: markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        date
        duration
        location
        registrationLink
        image {
          childImageSharp {
            gatsbyImageData(quality: 90, layout: FULL_WIDTH)
          }
        }
        title
      }
    }
    relatedPages: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        id: { in: $relatedPages }
        frontmatter: { title: { ne: "" } }
        rawMarkdownBody: { ne: "" }
      }
    ) {
      edges {
        node {
          ...BlogRollItem
        }
      }
    }
  }
`;
