import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import LocaleContext from '../context/localeContext';
import { BlogContent } from '../components/Content';
import Hero from '../components/Hero';
import Section from '../components/Section';
import LinkButton from '../components/LinkButton';
import BlogRoll from '../components/BlogRoll';

const Event = ({
  title,
  date,
  duration,
  location,
  registrationLink,
  heroImage,
  content,
  contentComponent,
  relatedPages,
}) => {
  const { locale } = useContext(LocaleContext);
  const ContentComponent = contentComponent ?? BlogContent;
  const formattedDate = DateTime.fromISO(date)
    .setLocale(locale)
    .toLocaleString(DateTime.DATE_FULL);
  const formattedTime = DateTime.fromISO(date)
    .setLocale(locale)
    .set({ second: 0 })
    .toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);

  return (
    <>
      <Hero image={heroImage} content={`## ${title}`} autoHeaderText={false} />
      <Section>
        <div className="container lg:flex py-12">
          <div className="pb-12 lg:pb-0 lg:w-1/3 lg:pr-12">
            <h3 className="mb-6">Event Information</h3>
            <table className="table-auto">
              <tbody className="align-baseline">
                <tr>
                  <td className="font-bold pr-4 pb-2">Date:</td>
                  <td className="text-2xl">{formattedDate}</td>
                </tr>
                <tr>
                  <td className="font-bold pr-4 pb-2">Time:</td>
                  <td className="text-2xl">{formattedTime}</td>
                </tr>
                <tr>
                  <td className="font-bold pr-4 pb-2">Duration:</td>
                  <td className="text-2xl">{duration}</td>
                </tr>
                <tr>
                  <td className="font-bold pr-4 pb-2">Location:</td>
                  <td className="text-2xl">{location}</td>
                </tr>
              </tbody>
            </table>
          </div>
          {registrationLink && (
            <div className="lg:w-2/3 lg:pl-12 lg:border-l lg:border-l-gray-400">
              <LinkButton text="Sign up" to={registrationLink} />
            </div>
          )}
        </div>
      </Section>
      <Section colorScheme="white" overlayLength="large">
        <div className="container py-12">
          <ContentComponent content={content} />
        </div>
      </Section>
      {relatedPages && !!relatedPages.length && (
        <Section overlaySide="left">
          <div className="container py-12">
            <div className="row flex flex-col md:flex-row pb-2 gap-x-36">
              <div className="md:w-1/3 flex flex-wrap content-between text-base">
                <h2 className="mb-6 md:mb-12">
                  <span className="bg-theme-underline">Related Content</span>
                </h2>
              </div>
            </div>
            <div className="row">
              <BlogRoll blogPosts={relatedPages} />
            </div>
          </div>
        </Section>
      )}
    </>
  );
};

Event.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  duration: PropTypes.string,
  location: PropTypes.string,
  registrationLink: PropTypes.string,
  heroImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  content: PropTypes.object.isRequired,
  relatedPages: PropTypes.arrayOf(PropTypes.object),
};

export default Event;
